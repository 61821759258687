@import "./Global.scss";

.thank-you-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  flex-direction: column;
  .thank-you-title {
    font-size: 20px;
    margin: 0 10px 20px 10px;
  }
  .capture-container {
    width: 90%;
    padding: 10px;
    .capture {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid #e1e1e1;
      border-top: 15px solid #b743e1;
      border-radius: 15px;
      text-align: center;
      .thank-you-data-container {
        display: flex;
        margin: 10px 0;
        justify-content: space-around;
      }
      .thank-you-data-text {
        width: 50%;
        font-size: 16px;
      }
      .event-name {
        color: $primary-color;
      }
      .event-record {
        font-size: 20px;
        margin: 10px 0 20px 0;
      }
      .detail-info {
        font-size: 16px;
      }
      .unique-code {
        color: $primary-color;
        font-size: 24px;
        margin: 20px 0;
      }
    }
  }
  .download-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .download-button {
      cursor: pointer;
      border: 1px solid $secondary-color;
      text-align: center;
      line-height: 30px;
      height: 30px;
      width: 150px;
      border-radius: 5px;
      &:hover {
        color: #fff;
        background-color: $primary-color;
      }
    }
  }

  .thank-you-sub-title {
    font-size: 16px;
    margin:  40px 10px;
  }
  .thank-you-text {
    font-size: 16px;
  }
}
