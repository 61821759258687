@import "./Global.scss";

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.App {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
  max-width: 640px;

  flex-direction: column;
  .logo-container {
    display: flex;
    justify-content: center;
    border-bottom: 3px solid $primary-color;
    .logo {
      height: 150px;
    }
  }
  .form-title {
    margin: 20px 10px;
    font-size: 24px;
    text-align: center;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="radio"] {
  margin-right: 8px;
  cursor: pointer;
}
input[type="radio"]:after {
  width: 14px;
  height: 14px;
  position: relative;
  top: -2px;
  left: -1px;
  background-color: $secondary-color;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"]:checked:after {
  width: 14px;
  height: 14px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $primary-color;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
  outline: 1px solid $primary-color;
}

@media screen and (max-width: 640px) {
  .App {
    .logo-container {
      display: flex;
      justify-content: center;
      border-bottom: 3px solid $primary-color;
      .logo {
        height: 250px;
      }
    }
  }
}
