@import "./Global.scss";

.form-page-container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 10px;

  .fields-marked {
    font-size: 14px;
  }

  .red-start {
    color: $error-color;
  }
  .end-text{
    text-decoration: line-through;
  }
  .end-button{
    cursor: not-allowed;
  }
  .s-font {
    font-size: 12px;
  }
  .form-container {
    margin: 50px 0;
    padding: 0 20px;
    .input-row {
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;
      .input-container {
        display: flex;
        flex-direction: column;
        width: 45%;
        height: 120px;

        .input-label-container {
          display: flex;

          .input-label {
            font-size: 16px;
            margin-bottom: 20px;
          }
        }
        .input-box {
          border: none;
          border-bottom: 1px solid $secondary-color;
          font-size: 16px;
          outline: none;
          border-radius: 0px;
          &:focus {
            border-bottom: 2px solid $primary-color;
            outline: none;
          }
          &::placeholder {
          }
        }

        .input-error-message {
          font-size: 14px;
          color: $error-color;
        }
      }
      .date-time-container {
        height: 280px;
        .input-date-time {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .pre-submit-button-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .pre-submit-button {
        cursor: pointer;
        border: 1px solid $secondary-color;
        text-align: center;
        line-height: 30px;
        height: 30px;
        width: 300px;
        border-radius: 5px;
        &:hover {
          color: #fff;
          background-color: $primary-color;
        }
      }
    }
  }

  .form-popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .form-popup {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      width: 400px;
      height: 150px;
      border: 1px solid $secondary-color;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 20px;
      border-top: 20px solid $primary-color;
      .form-popup-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .form-popup-text {
          margin-bottom: 30px;
        }
        .form-popup-button-container {
          display: flex;
          width: 250px;
          justify-content: space-between;
          .form-popup-button {
            cursor: pointer;
            width: 100px;
            text-align: center;
            border: 1px solid $secondary-color;
            background-color: #fff ;
            border-radius: 5px;
            &:hover {
              color: #fff;
              background-color: $primary-color;
            }
          }
        }
      }
    }
  }
  .loading-container {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #a0a0a04c;
    z-index: 999;
    .loadingImage {
      width: 100px;
      height: 100px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -50px;
      margin-top: -50px;
    }
  }
}
